<template>
    <b-container fluid>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{$t('elearning_tim.disciplinary_action')}}</h4>
          </template>
            <template v-slot:body>
                <b-row>
                  <b-col md="5" sm="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('elearning_config.fiscal_year')"
                        label-for="fiscal_year_id"
                    >
                      <v-select name="fiscalYear"
                        v-model="search.fiscal_year_id"
                        label="text"
                        :reduce="item => item.value"
                        :options= fiscalYearList
                        :placeholder="$t('globalTrans.select')"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="5" sm="12" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('elearning_config.organization')"
                        label-for="organization"
                    >
                      <v-select name="organization"
                                v-model="search.org_id"
                                label="text"
                                :reduce="item => item.value"
                                :options= orgList
                                :disabled="!$store.state.Auth.activeRoleId || $store.state.Auth.activeRoleId !== 1"
                                :placeholder="$t('globalTrans.select')"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="2" sm="12">
                      <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                  </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('elearning_tim.disciplinary_action_list')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                        <b-button class="primary btn_add_new" v-b-modal.modal-4 @click="resetId">
                             <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <a class="btn_table_action table_action_status" v-if="data.item.attachment" :href="trainingElearningServiceBaseUrl + 'storage/uploads/disciplinary-action/' + data.item.attachment" download><i class="fas fa-download"></i></a>
                                            <a href="javascript:" class="btn_table_action table_action_view" title="View Details" v-b-modal.modal-5 @click="details(data.item)"><i class="fas fa-eye"></i></a>
                                            <a href="javascript:" class="btn_table_action table_action_edit" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-ball-pen-fill"></i></a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-5" size="lg" :title="$t('elearning_tim.dixciplinary_action_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <b-button @click="pdfExportDetails" class="ml-4 btn-success water-test-pdf-button">
                <i class="fas fa-print"></i> {{  $t('globalTrans.print') }}
            </b-button>
            <Details :item="item" :key="id" ref="details"/>
        </b-modal>

    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { disciplinaryActionList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import Details from './Details'
// import ExportPdf from './export_pdf_details'
// import Store from '@/store'

export default {
    mixins: [ModalBaseMasterList],
    components: {
    Form, Details
    },
    data () {
    return {
        trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
        search: {
        org_id: 0,
        fiscal_year_id: 0
        },
        id: 0,
        item: '',
        personalInfoList: []
    }
    },
    computed: {
        fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
        },
        orgList: function () {
            return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
        },
        formTitle () {
            return (this.editItemId === 0) ? this.$t('elearning_tim.disciplinary_action') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_tim.disciplinary_action') + ' ' + this.$t('globalTrans.update')
        },
        columns () {
            const labels = [
            { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
            { label: this.$t('elearning_config.fiscal_year'), class: 'text-center' },
            { label: this.$t('elearning_config.organization'), class: 'text-center' },
            { label: this.$t('elearning_tim.trainee_triner_name'), class: 'text-center' },
            { label: this.$t('globalTrans.mobile'), class: 'text-center' },
            { label: this.$t('elearning_tim.disciplinary_action'), class: 'text-center' },
            { label: this.$t('globalTrans.action'), class: 'text-center w-15' }
            ]

            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'year_bn' },
                { key: 'org_bn' },
                { key: 'name_bn' },
                { key: 'mobile' },
                { key: 'disciplinary_action_bn' },
                { key: 'action' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'year' },
                { key: 'org' },
                { key: 'name' },
                { key: 'mobile' },
                { key: 'disciplinary_action' },
                { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    async created () {
        this.search = Object.assign({}, this.search, {
            org_id: this.$store.state.dataFilters.orgId,
            fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
        })
        this.loadData()
    },
    mounted () {
        core.index()
    },
    methods: {
        async searchData () {
            this.loadData()
        },
        details (item) {
            this.item = item
        },
        loadData () {
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(trainingElearningServiceBaseUrl, disciplinaryActionList, params).then(response => {
            if (response.success) {
                this.personalInfoList = response.personalInfoList
                this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                this.paginationData(response.data)
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getCustomDataList (data) {
            const listData = data.map(item => {
            const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
            const orgData = {}
            if (typeof orgObj !== 'undefined') {
                orgData.org = orgObj.text_en
                orgData.org_bn = orgObj.text_bn
            } else {
                orgData.org = ''
                orgData.org_bn = ''
            }
            const fiscalObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
            const fiscalData = {}
            if (typeof fiscalObj !== 'undefined') {
                fiscalData.year = fiscalObj.text_en
                fiscalData.year_bn = fiscalObj.text_bn
            } else {
                fiscalData.year = ''
                fiscalData.year_bn = ''
            }
            const userObj = this.personalInfoList.find(doc => doc.user_id === parseInt(item.trainee_or_trainer_id))
            const userData = {}
            if (typeof userObj !== 'undefined') {
                userData.name = userObj.text_en
                userData.name_bn = userObj.text_bn
            } else {
                userData.name = ''
                userData.name_bn = ''
            }
            const officeTypeObj = this.$store.state.commonObj.officeTypeList.find(doc => doc.value === parseInt(item.office_type_id))
            const officeTypeData = {}
            if (typeof officeTypeObj !== 'undefined') {
                officeTypeData.office_type = officeTypeObj.text_en
                officeTypeData.office_type_bn = officeTypeObj.text_bn
            } else {
                officeTypeData.office_type = ''
                officeTypeData.office_type_bn = ''
            }
            const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
            const officeData = {}
            if (typeof officeObj !== 'undefined') {
                officeData.office = officeObj.text_en
                officeData.office_bn = officeObj.text_bn
            } else {
                officeData.office = ''
                officeData.office_bn = ''
            }
            const traningTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
            const traningTypeData = {}
            if (typeof traningTypeObj !== 'undefined') {
                traningTypeData.traning_type = traningTypeObj.text_en
                traningTypeData.traning_type_bn = traningTypeObj.text_bn
            } else {
                traningTypeData.traning_type = ''
                traningTypeData.traning_type_bn = ''
            }
            const traningCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
            const traningCategoryData = {}
            if (typeof traningCategoryObj !== 'undefined') {
                traningCategoryData.traning_category = traningCategoryObj.text_en
                traningCategoryData.traning_category_bn = traningCategoryObj.text_bn
            } else {
                traningCategoryData.traning_category = ''
                traningCategoryData.traning_category_bn = ''
            }
            const traningTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
            const traningTitleData = {}
            if (typeof traningTitleObj !== 'undefined') {
                traningTitleData.traning_title = traningTitleObj.text_en
                traningTitleData.traning_title_bn = traningTitleObj.text_bn
            } else {
                traningTitleData.traning_title = ''
                traningTitleData.traning_title_bn = ''
            }

            return Object.assign({}, item, orgData, fiscalData, userData, officeTypeData, officeData, traningTypeData, traningCategoryData, traningTitleData)
            })
            return listData
        },
        pdfExportDetails () {
            this.$refs.details.pdfExport()
        }
    }
}
</script>
